/*Name (Last, First) and Student Id
Sunkoju,TarunKumar(1002077699)
Kotagiri, Arvind kumar Satyanarayana (1002126766)
Tamanampudi, Sai Sridhar (1002117404)
Tadivaka, Sai Swetha (1002112726)
Tallapelli, Sai Charan (1002073449)*/

.admin-user-popup {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.admin-user-popup-content {
  background-color: #fff;
  border-radius: 5px;
  padding: 20px;
  max-width: 80%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.admin-user-popup-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 2.5em;
  cursor: pointer;
}

.admin-user-popup-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.admin-user-popup-table-th,
.admin-user-popup-table-td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.admin-user-popup-table-th {
  background-color: #f2f2f2;
}

.admin-user-popup-table-tr:nth-child(even) {
  background-color: #f2f2f2;
}

.admin-user-popup-table-td-nda {
  text-align: center;
}

.admin-user-pop-up-table-action-btns {
  display: flex;
  justify-content: center;
  gap: 10px;
}
