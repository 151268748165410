/* /*Name (Last, First) and Student Id
Sunkoju,TarunKumar(1002077699)
Kotagiri, Arvind kumar Satyanarayana (1002126766)
Tamanampudi, Sai Sridhar (1002117404)
Tadivaka, Sai Swetha (1002112726)
Tallapelli, Sai Charan (1002073449)*/

.body{
  margin: 0;
  padding: 0;
}
.welcome-chat-container {
  padding: 15px;
}

.message-chat-container {
  flex-direction: column;
  margin: 3px;
  padding: 0 16px;
  overflow-y: auto;
  min-height: 300px;
  max-height: 300px;
}

.message-grid {
  max-width: 50%;
}

.message-text {
  font-size: 12px;
  padding: 0 8px;
}

.message-self {
  text-align: right;
}

.message-other {
  text-align: left;
}

.message-typography {
  padding: 8px;
}

.message-self-typography {
  background-color: #43a047;
  border-radius: 20px 20px 0px 20px;
  justify-content: flex-end !important;
  text-align: right;
}

.message-other-typography {
  background-color: #1976d2;
  border-radius: 20px 20px 20px 0px;
  justify-content: flex-start !important;
  text-align: left;
}

.controls-container {
  display: flex;
  align-items: center;
  padding: 8px;
}

.text-field {
  flex: 1;
  border: 1px solid gray;
  border-radius: 4px;
  padding-left: 2px;
}

.send-chat-button {
  margin-left: 8px; /* Adjust the margin as needed */
}
/* styles.css */

.discussion-container {
  display: grid;
  grid-template-rows: auto 1fr auto;
  height: 100vh;
  background-color: #4a4949;
  padding: 5px;
  width: 100%;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dialogContainer {
  margin-top: 5%;
  background-color: #fff;
  border-radius: 8px;
  max-width: 350px; /* Use your theme.shape.borderRadius here */
  border-radius: 15px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.4);
}

