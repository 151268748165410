/*Name (Last, First) and Student Id
Sunkoju,TarunKumar(1002077699)
Kotagiri, Arvind kumar Satyanarayana (1002126766)
Tamanampudi, Sai Sridhar (1002117404)
Tadivaka, Sai Swetha (1002112726)
Tallapelli, Sai Charan (1002073449)*/

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

.gradesClass {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.gradesHeaderClass {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #011558;
  color: white;
  padding: 20px 30px;
  margin-bottom: 30px;
}

.gradesh1Class {
  margin: 0;
}

.gradesContainer {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.gradesTableClass {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.gradesTh,
.gradesTd {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ccc;
}

.gradesTh {
  background-color: #011558;
  color: white;
}

.gradesTableTbodytr:nth-child(even) {
  background-color: #f2f2f2;
}

.gradesTableTbodytr:hover {
  background-color: #ddd;
}

.gradesFooterClass {
  margin-top: auto;
  background-color: #011558;
  color: #fff;
  text-align: center;
  padding: 10px;
  width: 100%;
}

@media (max-width: 768px) {
  .gradesTh,
  .gradesTd {
    font-size: 14px;
    padding: 8px;
  }
}
