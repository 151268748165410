/*Name (Last, First) and Student Id
Sunkoju,TarunKumar(1002077699)
Kotagiri, Arvind kumar Satyanarayana (1002126766)
Tamanampudi, Sai Sridhar (1002117404)
Tadivaka, Sai Swetha (1002112726)
Tallapelli, Sai Charan (1002073449)*/

body,
h1,
h2,
p,
a,
button {
  margin: 0;
  padding: 0;
}

.admin-page {
  background-size: cover;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.admin-navbar {
  background-color: #011558;
  color: #fff;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 10px;
}

.admin-navbar .admin-nav-a {
  text-decoration: none;
  color: #fff;
  margin: 0 15px;
}

.admin-content {
  padding: 20px;
}

.admin-dashboard-button {
  background-color: #011558;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  width: 20%;
}

.boxes {
  display: grid;
  gap: 10px;
  grid-template-columns: auto auto auto;
  margin-top: 20px;
}

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

.box {
  border-radius: 5px;
  margin-bottom: 20px;
  background-color: rgb(241, 241, 251);
  border: 2px solid #000;
  box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.1);
}

.box .admin-box-h2 {
  margin-bottom: 10px;
  font-size: 18px;
  padding: 20px;
  text-align: center;
  border-bottom: 2px solid grey;
}

.button-group {
  display: grid;
  gap: 10px;
  align-items: center;
  padding: 20px;
}

.button-group .admin-btngrp-btn {
  background-color: #011558;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
}

.admin-footer {
  margin-top: auto;
  background-color: #011558;
  color: #fff;
  text-align: center;
  padding: 10px;
  width: 100%;
}

.admin-chat {
  position: relative;
  top: 20px;
}

@media (max-width: 767px) {
  .boxes {
    display: grid;
    justify-content: center;
    grid-template-columns: auto;
  }
  .box {
    width: 90vw;
  }
  .admin-dashboard-button {
    width: 100%;
  }
}

@media (min-width: 767px) {
  .boxes {
    grid-template-columns: auto auto;
  }
}

@media (min-width: 1023px) {
  .boxes {
    grid-template-columns: auto auto auto;
  }
}
