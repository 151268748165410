/*Name (Last, First) and Student Id
Sunkoju,TarunKumar(1002077699)
Kotagiri, Arvind kumar Satyanarayana (1002126766)
Tamanampudi, Sai Sridhar (1002117404)
Tadivaka, Sai Swetha (1002112726)
Tallapelli, Sai Charan (1002073449)*/

.existing-policies-container {
  max-width: 100%;
  padding: 20px;
}

.expolicytable {
  width: 100%;
  border-collapse: collapse;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  overflow: hidden;
  margin-top: 20px;
}

.expolicyth,
.expolicytd {
  padding: 1rem;
  text-align: left;
}

.expolicyth {
  background-color: #011558;
  color: #fff;
}
.expolicytd {
  user-select: text;
  cursor: text;
}

.expolicytr:nth-child(even) {
  background-color: #f2f2f2;
}

.expolicytr:hover {
  background-color: #ddd;
}

.button-container {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.editpolicybutton {
  background-color: #3cb371; /* Green */
  color: #fff;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.editpolicybutton:hover {
  background-color: #2e8b57; /* Darker green on hover */
}

.savepolicybutton {
  background-color: #008cff; /* Blue */
  color: #fff;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.savepolicybutton:hover {
  background-color: #0076d5; /* Darker blue on hover */
}

.delete-button {
  background-color: #ff0000; /* Red */
  color: #fff;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.delete-button:hover {
  background-color: #ff3333; /* Darker red on hover */
}

@media screen and (max-width: 768px) {
  .expolicyth,
  .expolicytd {
    padding: 0.5rem;
  }

  .button-container {
    gap: 0.5rem;
  }
}

@media screen and (max-width: 630px) {
  .expolicyth,
  .expolicytd {
    padding: 0.3rem;
  }

  .button-container {
    gap: 0.3rem;
  }
  .existing-policies-container {
    padding: 5px;
    max-width: 80%;
  }
}

@media screen and (max-width: 530px) {
  .expolicyth,
  .expolicytd {
    padding: 0.1rem;
    font-size: 12px;
  }

  .button-container {
    gap: 0.1rem;
  }
  .editpolicybutton,
  .delete-button,
  .savepolicybutton {
    padding: 0.4rem 0.8rem;
    border-radius: 4px;
  }
  .existing-policies-container {
    margin-left: 10px;
  }
}
@media screen and (max-width: 440px) {
  .expolicyth,
  .expolicytd {
    font-size: 10px;
  }
}

@media screen and (max-width: 390px) {
  .expolicyth,
  .expolicytd {
    font-size: 8px;
    text-align: center;
  }
  .editpolicybutton,
  .delete-button,
  .savepolicybutton {
    padding: 0.3rem 0.6rem;
    border-radius: 2px;
  }
}
@media screen and (max-width: 340px) {
  .existing-policies-container {
    margin-left: 5px;
  }
}
