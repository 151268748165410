/*Name (Last, First) and Student Id
Sunkoju,TarunKumar(1002077699)
Kotagiri, Arvind kumar Satyanarayana (1002126766)
Tamanampudi, Sai Sridhar (1002117404)
Tadivaka, Sai Swetha (1002112726)
Tallapelli, Sai Charan (1002073449)*/

body,
h1,
label,
input,
button {
  margin: 0;
  padding: 0;
}

.profile-page {
  background-size: cover;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.profile-navbar {
  background-color: #011558;
  color: #fff;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 10px;
}

.profile-navbar .profile-nav-a {
  text-decoration: none;
  color: #fff;
  margin: 0 15px;
}

.profile-content {
  padding: 20px;
}

.profile-dashboard-button {
  background-color: #011558;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  width: 20%;
}

.profile-container {
  width: 80%;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.profile-h1 {
  font-size: 24px;
  margin-bottom: 20px;
}

.form-group {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.profile-label {
  font-weight: bold;
  width: 120px;
  margin-right: 10px;
}

.profile-input {
  width: 100%;
  padding: 10px;
  border: 1px solid #8fcbff;
  border-radius: 5px;
}

.buttons {
  display: flex;
  margin-top: 20px;
}

.save-button,
.cancel-button {
  background-color: #011558;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  margin: 0 10px;
}

.profile-input::-webkit-outer-spin-button,
.profile-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.profile-container {
  max-width: 800px;
  margin: 0px auto;
  padding: 20px;
  background-color: white;
  border: 2px solid #000;
  box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.1);
  background-color: rgb(241, 241, 251);
}

.profile-container img {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  margin-bottom: 20px;
}

.profile-details {
  width: inherit;
}

.profile-footer {
  margin-top: auto;
  background-color: #011558;
  color: #fff;
  text-align: center;
  width: 100%;
}

.profile-error-message {
  margin-bottom: 20px;
}

@media (max-width: 767px) {
  .form-group {
    flex-direction: column;
  }

  .profile-label {
    width: auto;
    margin-bottom: 5px;
  }

  .profile-input {
    margin-bottom: 10px;
  }

  .profile-dashboard-button {
    width: 100%;
  }

  .profile-footer {
    margin-top: 10px;
  }
}
