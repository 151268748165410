/*Name (Last, First) and Student Id
Sunkoju,TarunKumar(1002077699)
Kotagiri, Arvind kumar Satyanarayana (1002126766)
Tamanampudi, Sai Sridhar (1002117404)
Tadivaka, Sai Swetha (1002112726)
Tallapelli, Sai Charan (1002073449)*/
.new-policy-container {
  background-color: #f0f0f0;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin: 20px;
}

.new-policy-container h2 {
  border-bottom: 2px solid #333;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

.new-policy-container div {
  margin-bottom: 15px;
}

.new-policy-container label {
  font-weight: bold;
  display: block;
  margin-bottom: 5px;
}

.new-policy-container input[type="text"],
.new-policy-container input[type="date"],
.new-policy-container input[type="number"],
.new-policy-container textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  margin-top: 5px;
}

.new-policy-container button {
  padding: 10px 20px;
  background-color: #011558;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 10px;
}
