/*Name (Last, First) and Student Id
Sunkoju,TarunKumar(1002077699)
Kotagiri, Arvind kumar Satyanarayana (1002126766)
Tamanampudi, Sai Sridhar (1002117404)
Tadivaka, Sai Swetha (1002112726)
Tallapelli, Sai Charan (1002073449)*/

.evaluation-form {
  max-width: 400px;
  margin: 30px auto;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.evaluation-h2 {
  font-size: 24px;
  margin-bottom: 20px;
  color: #333;
  text-align: center;
}

.question .evaluation-p {
  font-weight: bold;
  margin-bottom: 10px;
}

.radio-group .evaluation-label {
  display: block;
  margin-bottom: 10px;
}

.comment-box .evaluation-label1 {
  display: block;
  font-weight: bold;
  margin-top: 10px;
}

.evaluation-textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  resize: vertical;
}

.evaluation-submit-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.evaluation-submit-button:hover {
  background-color: #0056b3; /* Darker blue on hover */
}
