/*Name (Last, First) and Student Id
Sunkoju,TarunKumar(1002077699)
Kotagiri, Arvind kumar Satyanarayana (1002126766)
Tamanampudi, Sai Sridhar (1002117404)
Tadivaka, Sai Swetha (1002112726)
Tallapelli, Sai Charan (1002073449)*/

body,
h1,
label,
select,
table,
th,
td {
  margin: 0;
  padding: 0;
}

.user-activity-page {
  background-size: cover;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.user-activity-navbar {
  background-color: #011558;
  color: #fff;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 10px;
}

.user-activity-navbar .user-activity-a {
  text-decoration: none;
  color: #fff;
  margin: 0 15px;
}

.user-activity-content {
  padding: 20px;
}

.dashboard-button {
  background-color: #011558;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  width: 20%;
}

.report-container {
  max-width: 800px;
  padding: 20px;
}

.user-activity-h1 {
  font-size: 24px;
  margin-bottom: 20px;
}
.activity-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.user-activity-th,
.user-activity-td {
  border: 1px solid #011558;
  padding: 10px;
  text-align: center;
}

.user-activity-footer {
  margin-top: auto;
  background-color: #011558;
  color: #fff;
  text-align: center;
  padding: 10px;
  width: 100%;
}

@media (max-width: 767px) {
  .report-container {
    padding: 10px;
  }

  .user-activity-h1 {
    font-size: 20px;
  }
  .dashboard-button {
    width: 100%;
  }
}
