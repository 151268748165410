/*Name (Last, First) and Student Id
Sunkoju,TarunKumar(1002077699)
Kotagiri, Arvind kumar Satyanarayana (1002126766)
Tamanampudi, Sai Sridhar (1002117404)
Tadivaka, Sai Swetha (1002112726)
Tallapelli, Sai Charan (1002073449)*/

.access-denied {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 94vh;
}

.access-denied-body {
  text-align: center;
}

.access-denied-content {
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.access-denied-heading {
  font-size: 24px;
  font-weight: bold;
}

.access-denied-message {
  font-size: 18px;
  margin-top: 10px;
}

.access-denied-button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #0074cc;
  color: #fff;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
}

.access-denied-footer {
  margin-top: auto;
  background-color: #011558;
  color: #fff;
  text-align: center;
  padding: 10px;
  width: 100%;
}
