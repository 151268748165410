@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

.pdf-viewer {
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 800px;
  overflow: auto;
  padding: 20px;
}

.pdf-content {
  font-size: 16px;
  line-height: 1.5;
  text-align: justify;
  margin-bottom: 20px;
  margin-top: 20px;
}

.pdf-content h1 {
  font-size: 24px;
  margin-bottom: 10px;
}

.download-button {
  background-color: #011558;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s;
}

.download-button:hover {
  background-color: #0056b3;
}

.pdf-viewer {
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 800px;
  overflow: auto;
  padding: 20px;
}

.pdf-content {
  font-size: 16px;
  line-height: 1.5;
  text-align: justify;
  margin-bottom: 20px;
  margin-top: 20px;
}

.pdf-content h1 {
  font-size: 24px;
  margin-bottom: 10px;
}

.download-button {
  background-color: #011558;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s;
}

.download-button:hover {
  background-color: #0056b3;
}
