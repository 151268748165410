/* Reset CSS */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Name (Last, First) and Student Id */
/* Sunkoju, TarunKumar (1002077699) */
/* Kotagiri, Arvind kumar Satyanarayana (1002126766) */
/* Tamanampudi, Sai Sridhar (1002117404) */
/* Tadivaka, Sai Swetha (1002112726) */
/* Tallapelli, Sai Charan (1002073449) */

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap");

.CourseDetail-container {
  display: flex;
  flex-direction: column;
  /* align-items: center;
  justify-content: center; */
  min-height: 100vh;
  background-color: rgb(247, 247, 247);
  text-align: center;
}

.course-editing {
  flex: 1;
  margin: 15px;
}

.Course-Container {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  max-width: 90%;
  margin: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
}

.Course-details-heading {
  font-size: 36px;
  text-align: center;
}

.Edit-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
  transition: background-color 0.3s;
}

.Edit-button:hover {
  background-color: #0056b3;
}

.Edit-input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-top: 10px;
}

.Save-button {
  background-color: #28a745;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
}

@media screen and (max-width: 768px) {
  .Course-Container {
    max-width: 95%;
  }
}

@media screen and (max-width: 480px) {
  .Course-Container {
    max-width: 100%;
    padding: 10px;
  }
}
