/*Name (Last, First) and Student Id
Sunkoju,TarunKumar(1002077699)
Kotagiri, Arvind kumar Satyanarayana (1002126766)
Tamanampudi, Sai Sridhar (1002117404)
Tadivaka, Sai Swetha (1002112726)
Tallapelli, Sai Charan (1002073449)*/

.divcontainer {
  text-align: center;
  background-color: #f4f4f4;
  padding: 20px;
}

.students-table {
  width: 80%;
  margin: 0 auto;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.students-table th,
.students-table td {
  border: 1px solid #ddd;
  padding: 12px;
  text-align: center;
}

.students-table th {
  background-color: #011558;
  color: white;
}

.start-discussion-btn {
  background-color: #011558;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.start-discussion-btn:hover {
  background-color: #2a74ab;
}

@media screen and (max-width: 350px) {
  .start-discussion-btn {
    padding: 8px 16px;
    border-radius: 3px;
    font-size: 12px;
  }
  .QAtitle {
    font-size: 5px;
  }
}
