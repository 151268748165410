/*Name (Last, First) and Student Id
Sunkoju,TarunKumar(1002077699)
Kotagiri, Arvind kumar Satyanarayana (1002126766)
Tamanampudi, Sai Sridhar (1002117404)
Tadivaka, Sai Swetha (1002112726)
Tallapelli, Sai Charan (1002073449)*/
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap");
:root {
  --body-color: #f0f0f0;
  --nav-color: #011558;
  --bg-color: #fff;
  --line-color: #000;
}
.repbody {
  font-family: "Poppins", sans-serif;
  background: var(--body-color);
  margin: 0;
  padding: 0;
}
.content {
  padding: 20px;
}

.dashboard-button {
  background-color: #011558;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  width: 20%;
}

.report-container {
  max-width: 800px;
  padding: 20px;
}

.reph1 {
  font-size: 24px;
  margin-bottom: 20px;
  color: #000;
}
.content .dashboard-button .link {
  color: #fff;
  text-decoration: none;
}
.course-label {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;
}

.replabel {
  font-weight: bold;
  margin-right: 10px;
}

.repselect {
  width: 100%;
  padding: 10px;
  border: 1px solid #011558;
  border-radius: 5px;
}

.grade-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.repth,
.reptd1 {
  border: 1px solid #011558;
  padding: 10px;
  text-align: center;
  background-color: #ffffff;
  color: #000;
}
.repgradeth{
  color:#000;
}

@media (max-width: 767px) {
  .report-container {
    padding: 10px;
  }

  .reph1 {
    font-size: 20px;
  }

  .replabel,
  .repselect {
    display: block;
    width: 100%;
    margin-bottom: 10px;
  }

  .dashboard-button {
    width: 100%;
  }
}
