/*Name (Last, First) and Student Id
Sunkoju,TarunKumar(1002077699)
Kotagiri, Arvind kumar Satyanarayana (1002126766)
Tamanampudi, Sai Sridhar (1002117404)
Tadivaka, Sai Swetha (1002112726)
Tallapelli, Sai Charan (1002073449)*/

.permissions-popup {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.permissions-popup-content {
  background-color: #fff;
  border-radius: 5px;
  padding: 20px;
  max-width: 80%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.permissions-popup-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  cursor: pointer;
}

.permissions-popup h2 {
  text-align: center;
  font-size: 24px;
  margin-bottom: 20px;
}

.permissions-popup-table {
  width: 100%;
  border-collapse: collapse;
}

.permissions-popup-table-tr {
  border-bottom: 1px solid #ddd;
}

.permissions-popup-table-th,
.permissions-popup-table-td {
  padding: 10px;
  text-align: left;
}

.permissions-popup-table-th {
  font-weight: bold;
}

.permissions-popup-table-td.permissions-popup-table-edit-button button {
  background-color: #4caf50;
  color: #fff;
  border: none;
  padding: 8px 16px;
  border-radius: 5px;
  cursor: pointer;
}

.permissions-popup-table-td.permissions-popup-table-td-nda {
  text-align: center;
  font-style: italic;
  color: #888;
}

.permissions-radio-group {
  display: flex;
  flex-direction: column;
}

.permissions-button-group {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.permissions-button-edit {
  font-size: 1.5em;
  width: 2vw;
}
