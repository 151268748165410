/*Name (Last, First) and Student Id
Sunkoju,TarunKumar(1002077699)
Kotagiri, Arvind kumar Satyanarayana (1002126766)
Tamanampudi, Sai Sridhar (1002117404)
Tadivaka, Sai Swetha (1002112726)
Tallapelli, Sai Charan (1002073449)*/

.chatbody {
  margin-top: 40px;
  padding: 0;
}

.chat-popup-button {
  position: absolute;
  bottom: -1px;
  right: 40px;
  background-color: #011558;
  color: #fff;
  border: none;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease;
  z-index: 2;
}

.chat-popup-button.open {
  background-color: #ff6347;
}

.robot-icon {
  width: 40px;
  height: 40px;
  fill: #fff;
}

.chat-container {
  position: absolute;
  bottom: 30px;
  right: 120px;
  max-width: 450px;
  width: 100%;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.4);
  overflow: hidden;
  z-index: 1;
  transition: right 0.3s ease, width 0.3s ease;
}

.chat-container.open {
  right: 20px;
}

.chat-header {
  background-color: #011558;
  color: #fff;
  padding: 1px;
  text-align: center;
  border-bottom: 2px solid #011558;
}

.chat-messages {
  max-height: 300px;
  overflow-y: auto;
  padding: 10px;
}

.user-message {
  background-color: #011558;
  color: #fff;
  border-radius: 15px 15px 0 15px;
  padding: 12px 20px;
  margin: 10px 10px 0 10px;
  align-self: flex-end;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

.bot-message {
  background-color: #f2f2f2;
  color: #333;
  border-radius: 15px 15px 15px 0;
  padding: 12px 20px;
  margin: 10px 10px 0 10px;
  align-self: flex-start;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

.user-input {
  width: calc(100% - 40px);
  padding: 15px;
  border: none;
  border-top: 2px solid #011558;
  border-radius: 0 0 15px 0;
  outline: none;
  margin: 10px 0;
}

.send-button {
  background-color: #011558;
  color: #fff;
  border: none;
  padding: 15px 30px;
  border-radius: 0 0 0 15px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  outline: none;
}

.send-button:hover {
  background-color: #011558;
}
@media (max-width: 1280px) {
  .chat-popup-button {
    bottom: 10px;
  }
}
@media (max-width: 900px) {
  .chat-popup-button{
    bottom: 40px;
  }
}  
@media (max-width: 300px) {
  .chat-container {
    width: 70%;
    right: 25%;
  }
  .chat-popup-button {
    bottom: 40px;
    right: 2%;
  }
}
