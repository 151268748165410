/*Name (Last, First) and Student Id
Sunkoju,TarunKumar(1002077699)
Kotagiri, Arvind kumar Satyanarayana (1002126766)
Tamanampudi, Sai Sridhar (1002117404)
Tadivaka, Sai Swetha (1002112726)
Tallapelli, Sai Charan (1002073449)*/

:root {
  --body-color: #ffffff;
  --nav-color: #011558;
  --bg-color: #fff;
}

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap");

#pc-main-div {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}
.pc-h6 {
  font-size: 25px;
  color: var(--bg-color);
  margin: 0;
}

.pc-main {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 20px;
  margin: 10px;
}

.pc-functionality {
  background-color: var(--bg-color);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border: 2px solid #000;
  border-radius: 5px;
  padding: 15px;
  margin: 5px;
  flex: 0 0 calc(45% - 20px);
}

.pc-functionality .pc-h2 {
  font-size: 20px;
  margin-bottom: 5px;
  color: black;
}

.pc-toggle-button {
  background-color: var(--nav-color);
  color: var(--bg-color);
  font-family: Trebuchet MS;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  margin-top: 10px;
  border-radius: 5px;
  width: 100%;
}

.pc-footer {
  margin-top: auto;
  background-color: #011558;
  color: #fff;
  text-align: center;
  padding: 10px;
  width: 100%;
}

.pc-nav {
  background: var(--nav-color);
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.pc-ul {
  margin: 0;
  padding: 0;
}

.pc-ul .pc-li {
  display: inline-block;
  list-style: none;
  margin: 0 10px;
}

.pc-ul .pc-li:first-child {
  margin-left: 0;
}

.pc-nav .pc-ul {
  text-align: center;
}

.pc-nav .pc-ul .pc-li {
  display: block;
  list-style: none;
  margin: 10px 0;
}

.pc-nav .pc-ul .pc-li .pc-a {
  color: var(--bg-color);
  text-decoration: none;
  padding: 5px 10px;
  display: block;
}

.pc-nav .pc-ul .pc-li .pc-a:hover {
  background-color: black;
  color: white;
}

.pc-nav-list {
  display: flex;
}

@media screen and (max-width: 768px) {
  .pc-functionality {
    flex: 0 0 calc(100% - 20px);
  }

  .pc-nav {
    display: block;
  }

  .pc-nav .pc-h6 {
    text-align: center;
  }

  .pc-nav-list {
    display: flex;
    flex-wrap: wrap;
  }
}

.pcChat {
  margin-top: 15px;
}

@media (min-width: 820px) {
  .pc-functionality {
    display: grid;
    align-content: space-between;
  }
}

/* Responsive Inquiry Inbox CSS */

/* Overall container */
.inquiry-container {
  margin: 20px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

/* Styling for individual inquiry items */
.inquiry-item {
  margin-bottom: 15px;
  padding: 15px;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  position: relative; 
}

.inquiry-item strong {
  font-size: 18px;
  color: #333;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}


.mark-as-read-button {
  background-color: #3498db;
  color: #fff;
  border: none;
  padding: 8px 12px;
  cursor: pointer;
  border-radius: 5px;
}

.mark-as-read-button:hover {
  background-color: #2980b9;
}

.read-status {
  color: #27ae60;
  position: absolute;
  bottom: 10px;
  right: 10px;
}

/* Media query for responsiveness */
@media screen and (max-width: 768px) {
  .inquiry-item {
      padding: 10px;
  }
}
/* UpdateCourseContent CSS */

/* Overall container */
.update-course-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px; /* Adding padding to the overall container */
}

.uc-h1 {
  font-size: 30px;
  text-align: center;
  margin: 20px 0;
  text-shadow: 1px 1px 1px #ccc;
}

.uc-course-box {
  border: 2px solid #000;
  border-radius: 5px;
  padding: 30px;
  margin: 10px;
  width: 100%;
  background-color: #f9f9f9;
  box-shadow: 3px 3px 5px #e0e0e0;
  max-width: 900px; /* Set a maximum width for the course boxes */
  margin: 10px auto; /* Center the boxes */
}

.uc-module-box {
  border: 1px solid #888;
  border-radius: 5px;
  padding: 15px;
  margin: 10px 0;
  background-color: #fff;
}

.uc-course-title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}

.uc-moduleid {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 8px;
}
.uc-modulename{
  font-size: 18px;
  font-weight: bold; /* Bold font for the module name, description, and content */
  margin-bottom: 6px;
}

.uc-module-box p {
  margin-bottom: 10px; /* Add spacing between other paragraphs in module boxes */
}

.uc-coursename,
.uc-moduledescription {
  font-weight: bold;
}

.edit-button {
  padding: 8px 12px;
  background-color: var(--nav-color);
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  width: auto;
  text-align: center;
  white-space: nowrap;
  display: inline-block;
}

.edit-button:hover {
  background-color: #8a8015;
}

/* Media query for responsiveness */
@media screen and (max-width: 768px) {
  .uc-course-box {
    width: calc(95% - 20px);
    max-width: none; /* Remove max-width on smaller screens */
  }
}
