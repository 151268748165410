/*Name (Last, First) and Student Id
Sunkoju,TarunKumar(1002077699)
Kotagiri, Arvind kumar Satyanarayana (1002126766)
Tamanampudi, Sai Sridhar (1002117404)
Tadivaka, Sai Swetha (1002112726)
Tallapelli, Sai Charan (1002073449)*/

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');

.aboutUs-header {
  position: relative;
  padding: 10px;
  background: #011558;
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.logo {
  color: #fff;
  height: 60px;
  font-size: 30px;
  line-height: 60px;
  padding: 0 20px;
  text-align: center;
  box-sizing: border-box;
  float: left;
  font-weight: 700;
  text-decoration: none;
}

.aboutUs-nav {
  float: right;
}

.clearfix {
  clear: both;
}

.aboutUs-nav .aboutus-links {
  margin: 0;
  padding: 0;
  display: flex;
}

.aboutUs-nav .aboutus-links .services-li {
  list-style: none;

}

.aboutUs-nav .aboutus-links .services-li .services-a {
  position: relative;
  display: inline-block;
  margin: 10px 0;
  font-size: 20px;
  padding: 10px 20px;
  text-decoration: none;
  color: #fff;
  ;
}

.aboutUs-nav .aboutus-links .services-li .services-a:hover {
  background: #1436e07e;
  color: #fff;
  transition: 0.5s;
}

#check {
  position: absolute;
  top: -100px;
}

.aboutUs-nav .aboutus-checkbtn {
  display: none;
  position: absolute;
  right: 5%;
  margin-top: 2%;
  transform: translateY(-50%);
}

.aboutUs-nav .aboutus-checkbtn .line {
  width: 30px;
  height: 5px;
  background-color: #fff;
  margin: 5px;
  border-radius: 3px;
  transition: all .3s ease-in-out;
}

.aboutUs-section .services-p {
  text-align: justify;
}

.aboutus,
.abtprg,
.choose {
  background-color: rgb(241, 241, 251);
  border: 2px solid #000;
  padding: 20px;
  margin: 30px;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.services-footer {
  background-color: #011558;
  color: #fff;
  text-align: center;
}

.services-h2 {
  color: #000000;
}

.services-ul {
  list-style-type: disc;
  margin-left: 20px;
}

.services-li {
  margin-bottom: 10px;
}

.nav-items .services-a:hover {
  background-color: #ddd;
  color: black;
}

.services-p .services-a {
  color: #007bff;
  text-decoration: none;
}

.services-p .services-a:hover {
  text-decoration: underline;
}

@media screen and (max-width: 768px) {
  .menu-toggle {
    margin: 20px;
  }

  .aboutus-checkbtn {
    display: block;
  }
  .aboutUs-nav .aboutus-links {
    position: fixed;
    width: 95%;
    height: auto;
    background: rgba(0, 0, 0, 0.8);
    top: 95px;
    left: -100%;
    margin-left: 18px;
    text-align: center;
    transition: all .5s;
    flex-direction: column;
    justify-content: space-evenly;
  }

  .aboutUs-nav .aboutus-links .services-li {
    display: block;
    margin: 20px 0;
    line-height: 30px;
  }

  .aboutUs-nav .aboutus-links .services-li .services-a {
    font-size: 30px;
    color: white;
  }

  .services-a:hover {
    background: #021b3050;
    transition: 0.5s;
  }

  #check:checked~.aboutus-links {
    left: 0;
  }

  #check:checked~.hero {
    display: none;
  }

  .aboutUs-nav .aboutus-checkbtn {
    display: block;
  }

  .aboutUs-nav :checked~.aboutus-checkbtn .line:nth-child(1) {
    transform: translateY(10px) rotate(225deg);
  }

  .aboutUs-nav :checked~.aboutus-checkbtn .line:nth-child(3) {
    transform: translateY(-10px) rotate(-225deg);
  }

  .aboutUs-nav :checked~.aboutus-checkbtn .line:nth-child(2) {
    opacity: 0;
  }

  .nav-items {
    text-align: center;
    padding: 15px 0;
  }

  .nav-items .services-a {
    display: block;
    margin: 10px 0;
  }

  .aboutUs-section {
    margin: 10px;
  }

  .services-h2 {
    font-size: 20px;
  }

  .aboutus,
  .abtprg,
  .choose {
    padding: 10px;
    margin: 10px;
  }
}

@media (max-width:415px) {
  .aboutUs-nav .aboutus-links {
    width: 90%;
    height: auto;
  }
}